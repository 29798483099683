.missing-information {
  border: 1px solid var(--doc-page-edit-missing-information);
  color: var(--doc-page-edit-missing-information);
  margin-left: 20px;
  margin-top: 20px;

  ul {
    list-style: disc !important;
    padding-left: 32px !important;
  }

  .missing-information-section {
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.ask-lexebra-button {
  align-items: center;
  color: var(--components-button-important-background) !important;
  display: flex;
  gap: 5px;
  text-align: left;
}
