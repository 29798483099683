.chat-page.view-page {
  .dropdown-heading {
    align-items: end !important;

    .arrow {
      display: none !important;
    }
  }

  .dropdown-content {
    width: max-content !important;
  }

  .question-text {
    text-indent: 40px;
  }

  textarea {
    margin-right: 10px;
    text-align: justify;
    white-space: normal;
  }

  div.response-text {
    font-family: var(--font-family);
    margin: 0;
    max-width: 100%;
    overflow: hidden;
    padding: 0;
    text-align: justify;
    word-wrap: break-word;

    p {
      font-size: 1rem;
      line-height: 20px;
      text-indent: 40px;
    }
  }

  .card.highlight-card {
    .card-body button.btn.btn-link.inline-link {
      font-size: 1rem;
      line-height: 1rem;
    }

    .card-text {
      cursor: default !important;
    }

    .response-action-buttons.submitting {
      cursor: wait;

      * {
        pointer-events: none;
      }
    }
  }

  .copy-button {
    color: var(--view-page-btn-link);

    button {
      font-size: 1rem;
      font-weight: 500;
    }
  }

  .opinion-tabs.nav {
    flex-wrap: nowrap;
    margin-bottom: 10px;
    margin-left: 1rem;
    margin-right: 0;
    margin-top: 1rem;

    .nav-item {
      min-width: 0;

      &:last-of-type {
        flex-shrink: 0;
      }

      &:only-child {
        flex-shrink: 1;
      }

      .nav-link {
        align-items: center;
        border-radius: 2px;
        color: var(--summary-view-page-nav-link);
        cursor: pointer;
        display: flex;
        gap: 5px;
        padding: 0.25rem 1rem;
        padding-top: 0;

        span {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .nav-link.active {
        background-color: transparent;
        border-bottom: 3px solid var(--summary-view-page-nav-link);
        color: var(--summary-view-page-nav-link-active);
        cursor: default;
      }
    }
  }

  .card-text button.btn.btn-link.inline-link,
  .card-text a.btn-link.inline-link {
    color: var(--view-page-btn-link);
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 1rem;
    text-align: justify;
    vertical-align: baseline;
  }
}
