%popover-contents {
  .accordion-item .btn-link {
    color: rgb(0 81 128);
  }

  .accordion-button {
    gap: 5px;
  }

  .table-responsive {
    margin-left: 0;
    margin-right: 0;
  }

  .list-group-item {
    border-bottom: 1px solid var(--components-horizontal-scroll-menu-border);
    border-bottom: none;
    padding-bottom: 0.2rem;
    padding-left: 0.5rem;
    padding-top: 0.2rem;

    &:last-child {
      border: none;
    }

    &:hover {
      svg {
        display: block;
      }
    }

    svg {
      align-self: center;
      color: var(--components-select-filter-dropdownIndicator);
      cursor: pointer;
      display: none;
      font-size: 1.1rem;
      margin-right: 2px;
      min-width: 1.1rem;

      &:hover {
        color: black;
      }
    }

    a {
      color: var(--search-page-results-table-title);
      font-weight: 600;
    }
  }

  .accordion {
    max-height: 70vh;
    overflow: auto;
  }

  .accordion-button {
    background-color: white;
    color: black;
    font-weight: 300;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;

    &::after {
      display: none;
    }

    .toggle-indicator {
      color: var(--components-select-filter-dropdownIndicator);
      margin-right: 2px;
    }

    &.collapsed {
      .toggle-indicator.up {
        display: none;
      }
    }

    &:not(.collapsed) {
      .toggle-indicator.down {
        display: none;
      }

      span.copy-button + .badge {
        height: min-content;
        margin-left: 0;
        opacity: 0.7;
      }
    }

    .badge {
      height: min-content;
      margin-left: auto;
      opacity: 0.7;
    }

    &:focus,
    &:active {
      box-shadow: none !important;
      outline: none !important;
    }
  }

  .accordion-body {
    padding: 0;
    padding-left: 1rem;
  }
}

.generic-popover {
  max-width: 40vw;
  min-width: 20vw;
  width: 40vw;

  @media (min-width: 768px) {
    min-width: 768px;
  }
  @media (max-width: 768px) {
    min-width: calc(100vw - 30px);
  }

  .popover-header {
    background-color: var(--app-div-root-background);
    border-bottom: 1px solid var(--search-page-results-current-filters-chip-border);

    svg {
      color: var(--components-select-filter-dropdownIndicator);
      height: 1.25rem;
      width: 1.25rem;

      &:hover {
        color: black;
      }
    }
  }

  .loading-bar {
    transform: none;
  }

  &.bs-popover-bottom > .popover-arrow,
  &.bs-popover-bottom > .popover-arrow::after,
  &.bs-popover-end > .popover-arrow,
  &.bs-popover-end > .popover-arrow::after {
    border-width: 0 0.5rem 0.5rem;
    display: none;
    top: 1px;
  }

  @extend %popover-contents;
}

.offcanvas-backdrop.show {
  opacity: 0.2 !important;
}

#documents-menu-modal,
#save-search-modal,
#saved-searches-modal,
#search-modal,
#edit-annotation-modal,
#submit-feedback-modal {
  @extend %popover-contents;

  height: auto;
  z-index: 1055;

  .accordion {
    max-height: none;
  }

  .offcanvas-body {
    padding: 1rem 0;
  }

  .list-group-item {
    padding-left: 0;

    svg {
      cursor: pointer;
      display: block !important;
    }
  }
}

.quick-view-drawer-overlay {
  z-index: 1049;
}

@mixin qv-drawer-list {
  @for $i from 0 through 9 {
    .quick-view-drawer-#{$i} {
      width: calc(40vw + #{(40 * $i)}px);
      z-index: #{(1060 - $i)};

      .offcanvas-body {
        padding: 0;
      }

      .highlight-card,
      .info-card {
        height: fit-content !important;
        margin-bottom: 1rem !important;
        margin-top: 0 !important;
      }
    }

    .quick-view-drawer-#{$i}.mobile-view {
      height: calc(80vh + #{(40 * $i)}px);
      width: 100vw;
    }

    .quick-view-drawer-overlay-#{$i} {
      z-index: #{(1060 - $i - 1)};
    }
  }
}

@include qv-drawer-list;

#save-search-modal,
#search-modal,
#edit-annotation-modal,
#submit-feedback-modal {
  .offcanvas-body {
    padding: 1rem;
  }
}

/* popover override */
%summary-search-popover-content {
  .search-panel {
    .input-group-text {
      background-color: rgb(249 250 251) !important;
    }

    .btn {
      border-color: rgb(206 212 218) !important;

      &:focus,
      &:active {
        box-shadow: none !important;
        outline: 0 !important;
      }
    }
  }
}

#search-panel-popover {
  min-width: 350px;
  position: fixed !important;

  &.bs-popover-bottom > .popover-arrow,
  &.bs-popover-bottom > .popover-arrow::after {
    border-width: 0 0.5rem 0.5rem;
    display: none;
    top: 1px;
  }

  &[data-popper-reference-hidden="true"] {
    margin-top: -8px;
  }

  @extend %summary-search-popover-content;
}

#summary-search-modal {
  .btn svg {
    font-size: 1.3rem !important;
  }
  @extend %summary-search-popover-content;
}
