
.loading-indicator .current {
  animation: blinker 3s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.25;
  }
}

.loading-indicator .current::after {
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  display: inline-block;
  overflow: hidden;
  vertical-align: bottom;

  /* ascii code for the ellipsis character */
  width: 0;
}

@keyframes ellipsis {
  to {
    width: 40px;
  }
}

@keyframes ellipsis {
  to {
    width: 40px;
  }
}