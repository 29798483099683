@use "../edit-page.scss";

.chat-page.edit-page {
  .response-tabs {
    margin-top: 30px;

    nav .nav-item.nav-link {
      flex: none;

      &.disabled {
        text-align: left;
      }
    }

    .response-tab {
      min-width: 100px;
    }
  }
}
